.containerCommande {
    height: calc(100vh - 100px);
    overflow-y: hidden
}

.btnsCommandeContainer {
display: flex;
flex-direction: column;
align-items: center;
}

.addCommandeBtn {
    width: 140px;
    height: 140px;
    border-radius: 5px;
    border: none;
    background-color: #F9B500;
    margin-bottom: 10px;
}

.pendingCommandeBtn {
    width: 140px;
    height: 140px;
    border-radius: 5px;
    border: none;
    background-color: #AA0102;
    margin-bottom: 10px;
}

.livredCommandeBtn {
    width: 140px;
    height: 140px;
    border-radius: 5px;
    border: none;
    background-color: #5E8214;
    margin-bottom: 10px;
}