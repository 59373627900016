.livreurLayout {
    width: 100%;
    display: flex;
    flex-direction: row;
}
.livreurLayoutBtn {
width: 160px;
display: flex;
flex-direction: column;
align-items: center;
padding-top: 55px;
}
.LivreurLayoutContent {
width: calc(100% - 160px);
}

.livraison-section-title {
    color: #343434;
    font-size: 22px;
    font-weight: bold;
}

.addCircuitBtn {
    width: 140px;
    height: 140px;
    border-radius: 5px;
    border: none;
    background-color: #F9B500;
    margin-bottom: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.addCircuitBtn div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.addCircuitBtn p {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    text-align: start;
    margin: 0;
}

.curcuitLivraisonBtn {
    width: 140px;
    border-radius: 5px;
    border: none;
    background-color: #fff;
    border: 1px solid #DBDBDB;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    margin-bottom: 10px;
}
.curcuitLivraisonBtn.active {
    border: 1px solid #AA0102;
}
.curcuitLivraisonBtn p {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    color: #343434;
    margin-left: 8px;
}

.LivreurCardContainer {
    display: flex; 
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.addLivreurBtn {
    width: 150px;
    height: 31px;
    border-radius: 5px;
    background-color: var(--secondary-color);
    color: #fff;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 0.5rem;
}

.addLivreurBtn span {
font-size: 14px;
color: #fff;
font-weight: bold;
}


.itineraireCardContainer {
    height: 450px;
    overflow-y: scroll;
}

.itineraireCardContainer::-webkit-scrollbar {
    width: 8px;
    /* Set the width of the scrollbar */
}

.itineraireCardContainer::-webkit-scrollbar-thumb {
    background: #F0F0F1;
    border: 1px solid rgba(10, 13, 28, 0.35);
    /* Color of the scrollbar thumb */

}

.itineraireCardContainer::-webkit-scrollbar-thumb:hover {
    background: #F0F0F1;
    /* Color of the scrollbar thumb on hover */
}

.itineraireCardContainer::-webkit-scrollbar-track {
    background: #fff;
    border: 1px solid rgba(10, 13, 28, 0.35);
    /* Color of the scrollbar track */


}