body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #F9F9FB;
}

.login-box {
  background-color: #fff;
  border-radius: 5px;
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  width: 80%;
  height: 80vh;
  min-width: 600px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
  margin: 20px auto 0;
}

.label-login {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;

}

.input-field-login {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #F3EFF5;

}

.button-login {
  background-color: var(--secondary-color);
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
  font-weight: 600;
  border-radius: 5px;
}

.button-login:hover {
  opacity: 0.8;
}

.login-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.login-password-link {
  font-size: 15px;
  color: #A3A6B2;
  margin-top: 20px;
  text-align: center;
}

.form-container-login {
  width: 400px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-body {
  width: 70%;
  height: 100%;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-box-left {
  width: 33%;
  height: 100%;
}

.login-box-left img {
  height: 100%;
}

/* .decoration-bar {
  background-color: var(--primary-color);
  height: 100vh;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.decoration-bar>img {
  width: 200px;
}

.errorText {
  color: red;
  font-size: 13px;
}

.form-group {
  margin-bottom: 10px;
}

.password-input-container {
  position: relative;
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #A3A6B2;
  display: flex;
  align-items: center;
}

.password-toggle-icon img {
  width: 20px;
  height: 20px;
  color: red;
}

.password-toggle-icon:hover {
  color: #000;
}

.container-mobi {
 display: none;
}
.container-web {
  display: block;
}

/* Styles pour les petits écrans, comme les smartphones (écrans de moins de 600px de large) */
@media screen and (max-width: 600px) {
  .container-web {
    display: none;
  }
  
  .login-box-left {
    display: none;
  }

  .form-container-login {
    width: 100%;
    height: auto;
  }

  .login-body {
    width: auto;
  }
  .login-box {
    background-color: #fff;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
   
  }
  .login-box  {
    height: auto;
    width: 96%;
    min-width: 96%;
    padding: 5px;
    background: linear-gradient(183deg, #ffffffc4, #ffffff75, #ffffffe0);;
    box-shadow: 0 0 2rem #0002;
    border: 1px solid #fff2;
  }
.login-password-link {
  color: var(--secondary-color);
  font-weight: 700;
}
  .container-mobi {
    display: block;
    background-image: url('../../images/Groupe\ 3075.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: calc(100vh - 81px);
  }

  .container4mob {
    background-color: #f8b400;
  }
  .errorText {
    font-weight: bold;
  }
}

/* Styles pour les tablettes (écrans entre 600px et 1024px de large) */
@media screen and (min-width: 601px) and (max-width: 1024px) {
  .container-web {
    display: none;
  }
  .login-box-left {
    display: none;
  }

  .form-container-login {
    width: 100%;
    height: auto;
  }

  .login-body {
    width: auto;
  }
  .login-box  {
    height: 100%;
  }
}