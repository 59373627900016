.enviClientContainer {
    background-color: #fff;
    border: 1px solid #DBDBDB;
    padding: 20px;
    border-radius: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.enviClientHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 70px;
}
.enviClientHeader  h2{
 font-size: 22px;
 font-weight: bold;
 color: var(--dark-color);
}
.enviClientHeader button{
    background-color: var(--secondary-color);
    border: none;
    border-radius: 5px;
    width: 60px;
    height: 40px;
}
.enviClientBody {
    overflow-y: scroll;
    height: 185px;
}
.enviClientBody::-webkit-scrollbar {
    width: 8px;
    /* Set the width of the scrollbar */
}

.enviClientBody::-webkit-scrollbar-thumb {
    background: #F0F0F1;
    border: 1px solid rgba(10, 13, 28, 0.35);
    /* Color of the scrollbar thumb */

}

.enviClientBody::-webkit-scrollbar-thumb:hover {
    background: #F0F0F1;
    /* Color of the scrollbar thumb on hover */
}

.enviClientBody::-webkit-scrollbar-track {
    background: #fff;
    border: 1px solid rgba(10, 13, 28, 0.35);
    /* Color of the scrollbar track */


}
.enviClientBodyColumn {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
}
.enviClientBodyColumn p {
    flex: 1;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: var(--dark-color);
    margin: 0;
}

.enviClientBodyColumn p:first-child {
    font-size: 16px;
    font-weight: 600;
    color: var(--dark-color);
}