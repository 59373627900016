.table-client {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    font-size: 18px;
    font-weight: 600;
}

.table-client th,
.table-client td {
    padding: 12px;
    text-align: left;
}

.table-client th {
    background-color: #fff;
    color: #707070;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 3px solid #F9F9FB;
    text-align: center;
}

.table-client td {
    color: #0F1637;
    font-size: 14px;
    font-weight: normal;
    text-align: center;
}

.table-client tr {
    border-bottom: 3px solid #F9F9FB;

}

.table-client tr:last-child {
    border-bottom: none;
}

.table-client td:last-child {
    white-space: nowrap;
}

.table-client td a {
    font-size: 14px;
    color: #FF7D1A !important;
    font-weight: 300;
    font-style: italic;
    text-decoration: none;
    cursor: pointer;
}

.tab-selected {
    background-color: #fff;
    width: 128px;
    height: 42px;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;

    cursor: pointer;

}

tr:hover {
    background-color: #EDEDED;
}

.selected_tr {
    background-color: #EDEDED;
}

.client-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.addClientBtn {
    width: 200px;
    background-color: #fff;
    color: var(--secondary-color);
    border-radius: 5px;
    height: 40px;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.addClientBtn svg {
    margin-right: 8px;
}

input.nosubmit2 {
    border: none;
    width: 275px;
    height: 40px;
    /* height: 50px; */
    padding: 9px 4px 9px 40px;
    border-radius: 5px;
    background: #fff url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' className='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
}

.card-client {
    background-color: #fff;
    border: 1px solid #DBDBDB;
    padding: 20px;
    border-radius: 10px;
}

.card-client h3 {
    font-size: 18px;
    font-weight: bold;
    color: ver(--dark-color);
    margin-bottom: 8px;
}

.card-client p {
    font-size: 14px;
    font-weight: 400;
    color: ver(--dark-color);
    margin-bottom: 4px;
}
.client-table-container {
    height: 400px;
    overflow-y: scroll;
}

.client-table-container::-webkit-scrollbar {
    width: 8px;
    /* Set the width of the scrollbar */
}

.client-table-container::-webkit-scrollbar-thumb {
    background: #F0F0F1;
    border: 1px solid rgba(10, 13, 28, 0.35);
    /* Color of the scrollbar thumb */

}

.client-table-container::-webkit-scrollbar-thumb:hover {
    background: #F0F0F1;
    /* Color of the scrollbar thumb on hover */
}

.client-table-container::-webkit-scrollbar-track {
    background: #fff;
    border: 1px solid rgba(10, 13, 28, 0.35);
    /* Color of the scrollbar track */


}