/* Styles généraux */
.navbar {
    background-color: #fff;
    padding: 10px 20px;
    width: 100vw;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto;
}

/* Logo */
.logo img {
    height: 40px;
    /* Ajustez la hauteur du logo */
}

/* Navigation */
.nav-items {
    display: flex;
}
.nav-items-mobile {
    display: none;
}
/* Liens de navigation */
.navBarBtnLinks {
    color: #AA0102;
    text-decoration: none;
    font-weight: 500;
    margin-right: 20px;
}

/* Styles pour le menu hamburger */
.menu-toggle {
    display: none;
    /* Masquer par défaut */
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    /* Taille du texte pour le bouton hamburger */
}

/* Styles pour les écrans plus petits */
@media (max-width: 768px) {

    .auth-buttons {
        flex-direction: column;
        /* Colonne pour les boutons d'authentification */
        align-items: flex-start;
        /* Aligne les éléments au début */

    }

    .nav-items {
        display: none;
        /* Masquer par défaut */
     
    }

    .nav-items-mobile.open {
        display: flex;
        /* Affiche le menu lorsque ouvert */
        align-items: center;
        flex-direction: column;
        flex-direction: column;
        /* Alignement vertical des éléments */
        width: 100%;
        /* Utilise toute la largeur */
        margin-top: 10px;
        /* Espace entre le bouton hamburger et les éléments */
    }

    .navBarBtnLinks {
        margin-right: 0;
        /* Supprime le margin pour un meilleur alignement */
        /* margin-bottom: 10px; */
        /* Ajoute de l'espace entre les éléments */
        height: 40px;
        display: flex;
        align-items: center;
    }

    .navbar {
        padding: 10px;
        /* Ajuste le padding pour les petits écrans */
    }

    .menu-toggle {
        display: block;
        /* Affiche le bouton hamburger sur les petits écrans */
    }
}


.header-container-landing {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.header-container-landing img {
    height: calc(100vh - 150px);
}

.sectionHeaderLanding {
    width: 70vw;
    margin: auto;
}

.sectionHeaderLanding h2 {
    font-size: 42px;
    font-weight: bolder;
    color: #AA0102;
    text-align: center;
}

.sectionHeaderLanding p {
    font-size: 18px;
    font-weight: 500;
    color: #343434;
    margin: 0;
    text-align: center;
}

.platListContainerLanding {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.header-image-w {
    display: block;
}

.header-image-m {
    display: none;
}

footer {
    padding: 20px;
    background-color: #343434;
}

.footer-Informations {
    color: #fff;
    list-style: none;
}

.footer-follow {
    color: #fff;
    list-style: none;
}

.footer-parag {
    color: #fff;
    margin: 0;
}

/* .footer-parag>li {
    margin-bottom: 10px;
} */
.footer-parag>svg {
    margin-right: 10px;
}

.footer-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.footer-image {
    max-width: 200px;
}

.social-m-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 200px;
}

/* Styles pour les petits écrans, comme les smartphones (écrans de moins de 600px de large) */
@media screen and (max-width: 600px) {
    .header-image-w {
        display: none;
    }

    .header-image-m {
        display: block;
    }

    .header-container-landing {
        background: rgb(255, 255, 255);
        background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 81%, rgba(170, 1, 2, 1) 81%);
        ;
        margin: 0 !important;
        padding-bottom: 20px;

    }

    .sectionHeaderLanding {
        width: 100%;
    }

    .sectionHeaderLanding h2 {
        font-size: 2rem;
    }

    .footer-parag {
        text-align: center;
    }
}



/* Styles pour les tablettes (écrans entre 600px et 1024px de large) */
@media screen and (min-width: 601px) and (max-width: 1024px) {}