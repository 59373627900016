/* @font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('./fonts/Poppins-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
   */
/* Add additional @font-face rules for other font weights and styles if needed */

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  /* font-family: 'Poppins', sans-serif; */
  margin: 0;
  background-color: #FAFBF9;
  font-family: 'Work Sans', sans-serif;
  width: 100vw;
  overflow-x: hidden;
}

:root {
  --primary-color: #AA0102;
  --secondary-color: #5E8214;
  --dark-color: #343434;
  --light-color: #E05901;
}
.container-Body-section {
  height: calc(100vh - 100px);
  overflow-y: scroll;
}
.container-Body-section::-webkit-scrollbar {
  width: 8px;
  /* Set the width of the scrollbar */
}

.container-Body-section::-webkit-scrollbar-thumb {
  background: #F0F0F1;
  border: 1px solid rgba(10, 13, 28, 0.35);
  /* Color of the scrollbar thumb */

}

.container-Body-section::-webkit-scrollbar-thumb:hover {
  background: #F0F0F1;
  /* Color of the scrollbar thumb on hover */
}

.container-Body-section::-webkit-scrollbar-track {
  background: #fff;
  border: 1px solid rgba(10, 13, 28, 0.35);
  /* Color of the scrollbar track */


}
.culumnFormContainer span {
  color: var(--primary-color);
  font-size: 12px;
}

.clientPlatCard {
  height: 100% !important;
}
