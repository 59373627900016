.rb-container-ficheClient {
    /* width: 50%;
      margin: auto; */
    display: block;
    position: relative;
  }
  
  .rb-container-ficheClient ul.rb {
    margin: 2.5em 0;
    padding: 0;
    display: inline-block;
  }
  
  .rb-container-ficheClient ul.rb li {
    list-style: none;
    margin: auto;
    margin-left: 2em;
    min-height: 50px;
    border-left: 1px dashed #CCD4E0;
    padding: 0 0 30px 30px;
    position: relative;
  }
  
  .rb-container-ficheClient ul.rb li:last-child {
    border-left: 0;
  }
  
  .rb-container-ficheClient ul.rb li:first-child::before {
    content: "";
    display: inline-block;
    width: 18.102px;
    height: 26.447px;
    margin-right: 5px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18.102' height='26.447' viewBox='0 0 18.102 26.447'><g id='Groupe_433' data-name='Groupe 433' transform='translate(-1.087)'><path id='Icon_ionic-ios-pin' data-name='Icon ionic-ios-pin' d='M16.926,3.375a8.791,8.791,0,0,0-9.051,8.5c0,6.612,9.051,17.947,9.051,17.947s9.051-11.335,9.051-17.947A8.791,8.791,0,0,0,16.926,3.375Zm0,12.136a2.982,2.982,0,1,1,2.948-2.982A2.965,2.965,0,0,1,16.926,15.511Z' transform='translate(-6.788 -3.375)' fill='%23ccd4e0'/><circle id='Ellipse_247' data-name='Ellipse 247' cx='4' cy='4' r='4' transform='translate(5.947 5.591)' fill='%23AA0102'/></g></svg>");
    border: none;
    background-color: transparent;
    top: -15px;
  }
  
  .rb-container-ficheClient ul.rb li::before {
    position: absolute;
    left: -9px;
    top: -12px;
    content: " ";
    border: 2px solid #CCD4E0;
    border-radius: 500%;
    background-color: #fff;
    height: 18px;
    width: 18px;
    transition: all 500ms ease-in-out;
  }
  
  .rb-container-ficheClient ul.rb .selectedAct::before {
    position: absolute;
    left: -9px;
    top: -12px;
    content: " ";
    border: 2px solid #CCD4E0;
    border-radius: 500%;
    background-color: #AA0102;
    height: 18px;
    width: 18px;
    transition: all 500ms ease-in-out;
  }
  
  
  .rb-container-ficheClient ul.rb li:hover::before {
    border-color: #232931;
    transition: all 1000ms ease-in-out;
  }
  
  .rb-container-ficheClient ul.rb li .timestamp {
    color: #0A0D1C;
    position: relative;
    font-size: 12px;
    font-weight: bold;
    top: -12px;
    cursor: pointer;
    /* width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
  }
  
  .rb-container-ficheClient .item-title {
    color: #858585;
    font-size: 10px;
  }
  
  .rb-container-ficheClient .item-title p {
    margin-left: 10px;
    margin-bottom: 0;
  }
  
  .rb-container-ficheClient .item-titleModal {
    color: #858585;
    font-size: 10px;
    font-weight: 300;
  }
  
  .rb-container-ficheClient .item-titleModal p {
    margin-left: 10px;
    margin-bottom: 0;
    font-weight: 300;
  }