/* admin header */
.header-gest-pages {
    /* width: 100%; */
    background-color: #fff;
    height: 100px;
    padding: 10px 30px;
    display: flex;
    flex-direction: row;
}

.header-gest-pages-left, .header-gest-pages-right {
    height: 100%;
    flex: 1;
}
.header-gest-pages-right{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.header-gest-pages-left>.text-and-back>h3 {
    font-size: 32px;
    font-weight: bold;
    color: var(--dark-color);
    /* margin-left: 20px; */
    margin-bottom: 0;
    margin-top: 0;
}
.header-gest-pages-left>.text-and-back {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}
.header-gest-pages-left>.text-and-back>a {
    cursor: pointer;
    text-decoration: none;
}

.text-and-back >button {
    background: none; 
  border: none; 
  margin-right: 10px;
  
}

.skipBtnCont {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.skipProssBtn {
    border: none;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.skipProssBtn >span{
   
    color: #AA0102;
    font-size: 22px;
    font-weight: bold;
}
.skipProssBtn>svg {
    margin-right: 10px;
}